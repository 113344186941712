@import 'styles/includes.scss';

.EventCategories {
    $root: &;

    position: relative;
    display: flex;
    padding-right: 16px;

    &__OuterContainer {
        position: relative;
        width: calc(100dvw - 36px);

        @include media(m) {
            width: calc(100% + 102px);
        }
    }

    &__CategoryContainer {
        display: flex;
        gap: 12px;
        position: relative;
        color: $colorGray40;
        overflow-x: scroll;
        padding-bottom: 12px;
        margin-right: 4px;

        @include media(m) {
            gap: 16px;
            padding-right: 10px;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__Fade {
        pointer-events: none;
        width: 77px;
        position: absolute;
        background: linear-gradient(
            90deg,
            rgba(24, 24, 24, 0) 2.79%,
            #181818 95.26%
        );
        height: 44px;
        display: block;
        right: -16px;
        top: 0;
        opacity: 1;
        transition: opacity 0.3s ease;

        @include media(m) {
            width: 200px;
            right: 0;
        }

        &--Left {
            z-index: 1;
            background: linear-gradient(
                90deg,
                #181818 4.74%,
                rgba(24, 24, 24, 0) 97.21%
            );
            left: -16px;
            right: initial;
        }

        &--Hidden {
            opacity: 0;
        }
    }

    &__Category {
        display: flex;
        gap: 8px;
        background-color: $colorBlack750;
        border: 1px solid $colorBlack750;
        align-items: center;
        padding: 10px 12px;
        border-radius: 45px;
        color: inherit;
        height: 36px;
        white-space: nowrap;
        transition: border 0.1s ease-in-out, background-color 0.3s ease;

        @include media(m) {
            padding: 10px 16px;
            height: 44px;
        }

        &:hover {
            border: 1px solid $colorPink;
        }

        &--Active {
            background-color: $colorPink600;
        }
    }
    &__Text {
        font-family: $fontBrand;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-size-adjust: none;
        letter-spacing: 0.3px;

        @include media(m) {
            font-size: 1.6rem;
        }
    }
    &__ButtonsContainer {
        display: none;

        @include media(m) {
            position: relative;
            z-index: 0;
            display: flex;
            align-items: center;
            gap: 4px;
            padding-left: 1.2rem;
            right: 0.4rem;
            padding-bottom: 12px;
        }
    }

    &__Button {
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        color: $colorWhite;
        border-radius: 50%;
        background-color: $colorPink;
        border: none;
        transition: background-color 0.3s ease;

        &[aria-disabled='false']:hover {
            cursor: pointer;
        }

        &--Disabled {
            cursor: initial;
            background-color: transparent;
        }

        &--Disabled {
            cursor: initial;
            background-color: transparent;
            &.EvenSelectedCategories__Button--Prev {
                visibility: hidden;
            }
        }

        &::before {
            color: $colorWhite;
            content: '';
            background-image: url('#{$assetPath}img/chevron_white.svg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: block;
            margin: auto;
            opacity: 1;
            z-index: 10;
            height: 24px;
            width: 24px;
        }

        &[aria-disabled='true'] {
            @include media(l) {
                display: flex;
                justify-content: center;
                opacity: 1 !important;
                // background-color: $colorWhiteBackground;

                &::before {
                    display: block !important;
                    color: $colorWhite;
                    margin: auto auto;
                    content: '';
                    visibility: hidden;
                    // background-image: url('#{$assetPath}img/chevron.svg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    opacity: 1;
                    z-index: 10;
                    height: 24px;
                    width: 24px;
                    @include media(l) {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            #{$root}--White & {
                @include media(l) {
                    background-color: $colorGray200 !important;
                }
            }
        }

        &--Prev {
            left: -10px;

            &:before {
                transform: rotate(-180deg);
            }

            @include media(s) {
                left: 0;
            }

            @include media(l) {
                right: 50px;
                left: unset;
            }
        }

        &--Next {
            right: -10px;
            left: unset;

            @include media(s) {
                right: 0;
            }
        }
    }
}
