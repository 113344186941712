@use "sass:math";

%container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 $spacingL;

    @include media(s) {
        padding: 0 $spacingL;
    }

    @include media(ml) {
        padding: 0 $spacingXl * 3;
    }

    @include media(l) {
        padding: 0 $spacingXl * 4;
    }

    @include media(xl) {
        max-width: (map-get($maxwidths, xl));
        padding: 0;
    }
}

%h1 {
    font-family: $fontBrand;
    font-size: 4rem;
    font-weight: bold;
    line-height: (52 * 0.025);
    letter-spacing: 0.5px;
    hyphens: auto;

    @include media(l) {
        font-size: 5.2rem;
        line-height: math.div(62, 52);
        letter-spacing: 1.5px;
    }
}

%h2 {
    font-family: $fontBrand;
    font-size: 2.6rem;
    font-weight: bold;
    line-height: math.div(34.3, 26);
    letter-spacing: 0.4px;

    @include media(l) {
        font-size: 3.5rem;
        line-height: math.div(46, 35);
        letter-spacing: 0.8px;
    }
}

%h3 {
    font-family: $fontBrand;
    font-size: 2.1rem;
    font-weight: bold;
    line-height: math.div(29, 21);
    letter-spacing: 0.2px;

    @include media(l) {
        font-size: 2.4rem;
        line-height: math.div(30, 24);
        letter-spacing: 0.5px;
    }
}

%h4 {
    font-family: $fontBrand;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: math.div(24, 17);
    letter-spacing: 0.2px;

    @include media(l) {
        font-size: 1.9rem;
        line-height: math.div(32, 19);
        letter-spacing: 0.4px;
    }
}

%h5 {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: math.div(20, 14);
    letter-spacing: 0.2px;

    @include media(l) {
        font-size: 1.6rem;
        line-height: math.div(24, 16);
        letter-spacing: 0.1px;
    }
}

%body-copy {
    font-family: $fontBrand;
    font-size: 1.6rem;
    line-height: math.div(26.4, 16);

    @include media(l) {
        font-size: 1.8rem;
        line-height: math.div(28.8, 18);
    }
}

%body-copy-14 {
    font-family: $fontBrand;
    font-size: 1.4rem;
    line-height: 1.4;

    @include media(l) {
        font-size: 1.6rem;
        line-height: 1.5;
    }
}

%body-2 {
    font-family: $fontBrand;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
}

%body-3 {
    font-family: $fontBrand;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
}

%body-4 {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.55;
    letter-spacing: normal;
}

%preamble {
    font-size: 2rem;
    line-height: math.div(33, 22);
    margin-bottom: 3rem;

    @include media(l) {
        font-size: 2.2rem;
        line-height: math.div(35.2, 22);
    }
}

%nav-titles {
    font-size: 1.7rem;
    letter-spacing: 0.2px;
}

%nav-subtitles {
    font-family: $fontBrand;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
}

%labels {
    font-family: $fontBrand;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 2.6px;

    @include media(m) {
        font-size: 1.4rem;
    }

    @include media(l) {
        font-size: 1.6rem;
    }
}

%links {
    color: $colorBlack750;

    font-size: 1.6rem;
    line-height: math.div(26.4, 16);

    @include media(l) {
        font-size: 1.8rem;
        line-height: math.div(28.8, 18);
    }

    &:hover {
        color: $colorPink;
    }
}

%rich-text {
    font-family: $fontBrand;
    color: $colorBlack800;

    h1 {
        @extend %h1;
    }

    h2 {
        @extend %h2;
    }

    h3 {
        @extend %h3;
    }

    h4 {
        @extend %h4;
    }

    h5 {
        @extend %h5;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        padding-top: $spacingM;
        padding-bottom: $spacingXs;
    }

    a {
        @extend %links;
    }

    p {
        @extend %body-copy;
        margin-bottom: $spacingXl;
        color: $colorBlack750;
        max-width: $maxWidthText;

        @include media(l) {
            margin-bottom: 2.8rem;
        }
    }

    b {
        font-weight: bold;
    }

    strong {
        font-weight: bold;
    }

    i {
        font-style: oblique;
    }

    em {
        font-style: oblique;
    }

    figure {
        margin-top: 2.8rem;
        margin-bottom: $spacingXl;
        position: relative;

        @include media(l) {
            margin-top: 3rem;
            margin-bottom: $spacingXl * 2;
        }
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    figcaption {
        margin-top: 0.4rem;
        margin-bottom: 23px;
        font-family: $fontBrand;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.55;
        letter-spacing: normal;
        color: $colorGray700;
        text-align: right;
    }

    blockquote {
        text-align: left;
        display: flex;
        max-width: 276px;
        margin: 4.6rem auto;
        flex-direction: column;
        font-size: 1.6rem;
        letter-spacing: 0.02rem;
        line-height: 2.4rem;
        font-weight: bold;
        color: $colorBlack;
        z-index: 2;
        margin-bottom: 0.8rem;
        position: relative;
        min-height: 14.4rem;

        @include media(m) {
            max-width: 55.7rem;
            font-size: 2rem;
            line-height: 1.5;
            margin-bottom: 1.6rem;
            min-height: 15.7rem;
        }

        &::before {
            position: absolute;
            display: block;
            top: -1.8rem;
            left: -3.6rem;
            content: '';
            border-left: 5.9rem solid $colorPurple50;
            border-right: 5.9rem solid transparent;
            border-bottom: 7.2rem solid $colorPurple50;
            border-top: 7.2rem solid transparent;
            z-index: -4;

            @include media(m) {
                border-left: 6.45rem solid $colorPurple50;
                border-right: 6.45rem solid transparent;
                border-bottom: 7.85rem solid $colorPurple50;
                border-top: 7.85rem solid transparent;
                top: -3.3rem;
                left: -5.3rem;
            }
        }

        &::after {
            position: absolute;
            display: block;
            content: '';
            background-image: url('#{$assetPath}img/quotation.svg');
            background-repeat: no-repeat;
            top: 0.5rem;
            left: -2.1rem;
            bottom: 0;
            background-size: 1.5rem 1.2rem;
            width: 1.5rem;
            height: 1.2rem;

            @include media(m) {
                background-size: 2rem 1.7rem;
                height: 1.7rem;
                width: 2rem;
                left: -2.9rem;
            }
        }
    }

    blockquote cite {
        text-align: center;
        font-size: 1.2rem;
        font-weight: normal;

        @include media(m) {
            font-size: 1.6rem;
            line-height: 2.64rem;
            text-align: right;
        }
    }

    ol,
    ul {
        color: $colorBlack750;
        @extend %body-copy;
        margin-bottom: $spacingXl;

        @include media(l) {
            margin-top: -0.4rem;
        }

        & li {
            position: relative;
            padding-left: $spacingXl;
        }

        & li:not(:last-child) {
            margin-bottom: $spacingL;
        }

        & li::before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: $colorPink;
            position: absolute;
            top: 1.4rem;
            left: 0;
            transform: translateY(-50%);
        }
    }
}
