@import 'styles/includes.scss';

.EventSubmission {
    background-color: $colorBlack800;
    font-family: $fontBrand;
    padding: 4.4rem 0;

    @include media(l) {
        padding: 5.6rem 0;
    }

    &__Grid {
        @extend %container;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__Title {
        font-size: 1.7rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: 0.02rem;
        color: $colorWhite;
        text-align: center;
        margin-bottom: $spacingXl;

        @include media(l) {
            font-size: 1.9rem;
            line-height: 1.68;
            letter-spacing: 0.04rem;
        }
    }
}
